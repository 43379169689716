import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <panel type="info">
      <p>
        The GorillaStack platform will shut down on <strong>31st December 2024</strong>.
      </p>
      <p>
        Check the <Link to="docs/sunset_faq/general">Sunset FAQs</Link> page for further details.
      </p>
    </panel>
    <h1>GorillaStack Documentation</h1>
    <p>
      Here you can find documentation on getting started with the GorillaStack platform, creating
      Rules, managing Users, information on some of our Actions and Triggers, as well as a full
      reference for our API.
    </p>
    <h2>Quick links</h2>
    <p>
      <Link to="/docs/user_guide/overview">Overview</Link>
    </p>
    <p>
      <Link to="/docs/user_guide/quick_start/sign_up">Quick Start Guide</Link>
    </p>
    <p>
      <Link to="/docs/user_guide/rules/overview">Getting Started With Creating Rules</Link>
    </p>
    <p>
      <Link to="/docs/user_guide/user_management/inviting_team_members">User Management</Link>
    </p>
    <p>
      <Link to="docs/reference/overview">Reference</Link>
    </p>
    <ul>
      <li>
        <Link to="/docs/reference/saml/saml_assertions">SAML Assertions</Link>
      </li>
      <li>
        <Link to="/docs/reference/api/overview">API Overview</Link>
      </li>
      <li>
        <Link to="/swagger/v2">V2 API Reference</Link>
      </li>
      <li>
        <Link to="/swagger/v1">V1 API Reference</Link>
      </li>
      <li>
        <Link to="/docs/reference/actions/overview">Actions</Link>
      </li>
      <li>
        <Link to="/docs/reference/triggers/overview">Triggers</Link>
      </li>
    </ul>
  </Layout>
);
IndexPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default IndexPage;
